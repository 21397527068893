import { useState, Fragment, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import { sendReset, passwordReset } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

function Copyright() {
  return (
    <Typography variant="body2" color="black" align="center">
      {"Copyright © "}
      Elemzy {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Forgot() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [isSubmitting, setIsSubmitting] = useState("");

  const state = useSelector((state) => state);

  function onSubmit(e) {
    e.preventDefault();
    if (password !== password2) {
      dispatch(alertMessage("ERROR", "Error.", "Passwords do not match."));
    } else {
      dispatch(passwordReset(token, password));
    }
  }

  function onClick() {
    setIsSubmitting(true);
    dispatch(sendReset(email));
  }

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (state.auth.alert) {
    setSnack({
      title: state.auth.alert.title,
      message: state.auth.alert.message,
      type: state.auth.alert.type,
    });
    setShowSnack(true);
    setIsSubmitting(false);
    dispatch({ type: "REMOVE_ALERT" });
  }

  if (state.auth.loginFailed) {
    return <Navigate to="/login" />;
  } else {
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          open={showSnack}
          onClose={() => setShowSnack(false)}
        >
          <Card style={{ minWidth: "350px", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {snack["type"] == "SUCCESS" ? (
                  <CheckCircleIcon
                    style={{ color: "#2ca25f", fontSize: "44px" }}
                  />
                ) : snack["type"] == "MESSAGE" ? (
                  <ErrorIcon style={{ color: "#3182bd", fontSize: "44px" }} />
                ) : (
                  <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
                )}
                <div style={{ marginLeft: "10px" }}>
                  <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                  <br></br>
                  <span>{snack["message"]}</span>
                </div>
              </div>
              <IconButton size="small">
                <CloseIcon
                  onClick={() => setShowSnack(false)}
                  style={{ color: "#3182bd" }}
                />
              </IconButton>
            </div>
          </Card>
        </Snackbar>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "white",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href={"https://teacher.elemzy.com"}
            >
              <img
                style={{ maxHeight: "50px", margin: "10px" }}
                src={require("../img/appbar.png")}
              />
            </a>
            <a
              style={{ textDecoration: "none" }}
              href={"https://teacher.elemzy.com"}
            >
              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  marginTop: "3px",
                }}
              >
                <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
              </Button>
            </a>
          </Toolbar>
        </AppBar>
        <div
          style={{
            backgroundColor: "white",
            justifyContent: "center",
            display: "flex",
            marginTop: "90px",
            transition: "opacity 1s",
            opacity: loaded ? 1 : 0,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginTop: "20px",
              width: "500px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "black",
              }}
            >
              <div style={{ marginTop: "-7px" }}>
                <span style={{ fontSize: "40px" }}>Elemzy</span>
                <br></br>
                <span style={{ fontSize: "30px" }}>Password Recovery</span>
              </div>
              <a
                style={{ textDecoration: "none" }}
                href={"https://teacher.elemzy.com"}
              >
                <img
                  style={{ maxHeight: "90px" }}
                  src={require("../img/onlylogo.png")}
                />
              </a>
            </div>
            <div style={{ marginTop: "28px" }}>
              <span>
                Follow these steps to recover password for your Elemzy account.
              </span>
              <h2>Step 1</h2>
              <p>
                Enter the <b>registered e-mail address</b> for elemzy.
              </p>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                id="email"
                autoComplete="new-password"
                autoFocus
                value={email}
                style={{ backgroundColor: "white", borderRadius: "6px" }}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                placeholder={"Registered E-mail"}
              />

              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
                onClick={() => onClick()}
              >
                Send email
              </Button>
              <br></br>
              <br></br>
              {isSubmitting ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div
            style={{
              marginLeft: "80px",
              width: "500px",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <h2>Step 2</h2>
            <p>
              Paste the <b>reset key</b> recieved in the provided email.
            </p>
            <form onSubmit={onSubmit} autoComplete="off">
              <TextField
                variant="outlined"
                margin="normal"
                required
                name="reset_key"
                id="reset_key"
                autoComplete="new-password"
                fullWidth
                placeholder="Reset Key"
                value={token}
                style={{ backgroundColor: "white", borderRadius: "6px" }}
                onChange={(e) => setToken(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                id="password"
                autoComplete="new-password"
                placeholder="Password"
                type="password"
                value={password}
                style={{ backgroundColor: "white", borderRadius: "6px" }}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password2"
                id="password2"
                autoComplete="new-password"
                placeholder="Confirm Password"
                type="password"
                value={password2}
                style={{ backgroundColor: "white", borderRadius: "6px" }}
                onChange={(e) => setPassword2(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: "20px" }}
              >
                Reset password
              </Button>
            </form>
          </div>
        </div>
        <Box style={{ position: "fixed", right: 20, bottom: 20 }}>
          <Copyright />
        </Box>
      </Fragment>
    );
  }
}
