import { useState, Fragment, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import { login } from "../actions/auth";
import { useSelector, useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      Elemzy {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [clearText, setClearText] = useState("");

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  function onSubmit(e) {
    setIsLoggingIn(true);
    e.preventDefault();
    dispatch(login(email, password)).then(() => setIsLoggingIn(false));
  }

  if (state.auth.alert) {
    if (state.auth.alert.title == "Welcome!") {
    } else {
      setSnack({
        title: state.auth.alert.title,
        message: state.auth.alert.message,
        type: state.auth.alert.type,
      });
      setShowSnack(true);
      dispatch({ type: "REMOVE_ALERT" });
    }
  }

  if (state.auth.isAuthenticated) {
    if (state.auth.user.is_teacher) {
      if (state.auth.csrfToken) {
        return <Navigate to="/" />;
      } else {
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: "46vh",
            }}
          >
            <CircularProgress />
          </div>
        );
      }
    } else {
      // alert.show("Please Login as principal");
      dispatch({
        type: "AUTH_ERROR",
      });
    }
  } else {
    
    return (
      <Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          open={showSnack}
          onClose={() => setShowSnack(false)}
        >
          <Card style={{ minWidth: "350px", padding: "10px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                {snack["type"] == "SUCCESS" ? (
                  <CheckCircleIcon
                    style={{ color: "#2ca25f", fontSize: "44px" }}
                  />
                ) : snack["type"] == "MESSAGE" ? (
                  <ErrorIcon style={{ color: "#3182bd", fontSize: "44px" }} />
                ) : (
                  <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
                )}
                <div style={{ marginLeft: "10px" }}>
                  <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                  <br></br>
                  <span>{snack["message"]}</span>
                </div>
              </div>
              <IconButton size="small">
                <CloseIcon
                  onClick={() => setShowSnack(false)}
                  style={{ color: "#3182bd" }}
                />
              </IconButton>
            </div>
          </Card>
        </Snackbar>
        <AppBar
          position="fixed"
          style={{
            backgroundColor: "white",
          }}
        >
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <a
              style={{ textDecoration: "none" }}
              href={"https://teacher.elemzy.com"}
            >
              <img
                style={{ maxHeight: "50px", margin: "10px" }}
                src={require("../img/appbar.png")}
              />
            </a>
            <a
              style={{ textDecoration: "none" }}
              href={"https://teacher.elemzy.com"}
            >
              <Button
                style={{
                  backgroundColor: "white",
                  color: "black",
                  fontSize: "16px",
                  marginTop: "3px",
                }}
              >
                <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
              </Button>
            </a>
          </Toolbar>
        </AppBar>
       
        <div
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "opacity 1s",
            opacity: loaded ? 1 : 0,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage:
                "linear-gradient(to right, rgba(33,41,100,0.9),  rgba(33, 41, 100, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
              height: "100%",
              paddingTop: "50px",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                boxShadow: "0 8px 18px 0 rgba(0,0,0,0.2)",
                padding: "20px",
                borderRadius: "6px",
                backgroundColor: "white",
                paddingRight: "20px",
                maxWidth: "350px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "black",
                }}
              >
                <div style={{ marginTop: "-7px" }}>
                  <span style={{ fontSize: "32px" }}>Elemzy</span>
                  <br></br>
                  <span style={{ fontSize: "20px" }}>Teacher Login</span>
                </div>
                <a
                  style={{ textDecoration: "none" }}
                  href={"https://teacher.elemzy.com"}
                >
                  <img
                    style={{ maxHeight: "60px" }}
                    src={require("../img/onlylogo.png")}
                  />
                </a>
              </div>
              <div
                className={state.auth.alert ? "shake" : ""}
                style={{
                  height: "20px",
                  marginTop: "15px",
                  textAlign: "center",
                  color: "red",
                }}
              >
                {state.auth.alert
                  ? state.auth.alert.type == "ERROR"
                    ? state.auth.alert.message
                    : ""
                  : ""}
              </div>
              <div
                style={{
                  margin: "5px 0px 0px 0px",
                }}
              >
                <form onSubmit={onSubmit}>
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: "6px" }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                  />
                  <TextField
                    style={{ backgroundColor: "white", borderRadius: "6px" }}
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    margin="normal"
                    placeholder="Password"
                    type={clearText ? "text" : "password"}
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          style={{ marginRight: "-8px", paddingLeft: "10px" }}
                          onClick={() => setClearText(!clearText)}
                        >
                          {clearText ? (
                            <VisibilityOffIcon style={{ color: "#3182bd" }} />
                          ) : (
                            <VisibilityIcon style={{ color: "#3182bd" }} />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <Link style={{ color: "black" }} to="/forgotpassword">
                      Forgot Password?
                    </Link>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      style={{ height: "30px", backgroundColor: "#3283c9" }}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          width: "100px",
                        }}
                      >
                        Login
                      </span>
                    </Button>
                  </div>
                  {isLoggingIn ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <CircularProgress />{" "}
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <Button
                    style={{
                      borderRadius: "6px",
                      paddingRight: "20px",
                      textAlign: "center",
                      marginTop: "20px",
                      width: "350px",
                    }}
                    variant={"outlined"}
                    onClick={() => navigate("/initialize", { replace: false })}
                  >
                    {" "}
                    Register as Teacher Here!
                  </Button>
                </form>
              </div>
            </div>
          </div>

          <Box style={{ position: "absolute", right: 20, bottom: 20 }}>
            <Copyright />
          </Box>
        </div>
      </Fragment>
    );
  }
}
