import { Hidden } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { API_URL } from "../actions/auth";
import clsx from "clsx";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import { useSelector, useDispatch } from "react-redux";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VideocamIcon from "@mui/icons-material/Videocam";
import MessageIcon from "@mui/icons-material/Message";
import RestoreIcon from "@mui/icons-material/Restore";
import { loadUser, login } from "../actions/auth";
import ClassIcon from "@mui/icons-material/Class";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { logout } from "../actions/auth";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import Slide from "@mui/material/Slide";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getNotifications } from "../actions/auth";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  color: "#3182bd",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& a": {
    color: "gray",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
    },
  },
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Sidebar(props) {
  const [snackState, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const navigate = useNavigate();

  const [showSnack2, setShowSnack2] = useState(false);
  const [snack2, setSnack2] = useState("");
  const dispatch = useDispatch();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [randomText, setRandom] = useState(makeid(4));
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [audio, setAudio] = useState(
    new Audio(require("../sounds/notification.mp3"))
  );

  const isMenuOpen = Boolean(anchorEl);
  const isNotificationsOpen = Boolean(anchorE2);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleNotificationsMenuOpen = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorE2(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  function playAudio() {
    const audioPromise = audio.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then((_) => {
          // autoplay started
          console.log(audioPromise);
        })
        .catch((err) => {
          // catch dom exception
          console.info(err);
        });
    }
  }

  const config = {
    headers: {
      Authorization: state.auth.token,
      "X-CSRFToken": state.auth.csrfToken,
      "Content-Type": "application/json",
    },
  };

  function _retrieveData() {
    try {
      var installDate = localStorage.getItem("installDate");
      if (installDate == null) {
        _storeData();
      } else if (moment(installDate, "DD-MM-YYYY").diff(moment(), "days") > 3) {
        _storeData();
        setOpenError(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function _retrieveData2() {
    try {
      var installDate = localStorage.getItem("installDate");
      if (installDate == null) {
        _storeData();
      } else if (moment(installDate, "DD-MM-YYYY").diff(moment(), "days") > 7) {
        _storeData();
        setOpenFeedback(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function _storeData() {
    localStorage.setItem(
      "installDate",
      new moment().format("DD-MM-YYYY").toString()
    );
  }

  useEffect(() => {
    _retrieveData();
    _retrieveData2();
    dispatch(getNotifications());
    audio.load();
  }, []);

  function updateNotificationSeen(notification_id) {
    var body = {
      email: state.auth.user.email,
      notification_id: notification_id,
    };
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_notification_seen", body, config)
      .then((res) => {
        getNotifications();
      });
  }

  function clearAllNotifications() {
    var body = {
      email: state.auth.user.email,
      notifications: state.auth.notifications,
    };
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/clear_all_notifications", body, config)
      .then((res) => {
        dispatch(getNotifications());
      });
  }
  var name;
  switch (window.location.pathname) {
    case "/":
      name = "Dashboard";
      break;
    case "/dashboard":
      name = "Dashboard";
      break;
    case "/ranking":
      name = "Ranking";
      break;
    case "/messenger":
      name = "Messenger";
      break;
    case "/notifications":
      name = "Notifications";
      break;
    case "/students":
      name = "Students";
      break;
    case "/settings":
      name = "Settings";
      break;
    case "/support":
      name = "Support";
      break;
    case "/results":
      name = "Results";
      break;
    case "/classes":
      name = "Classes";
      break;
    case "/events":
      name = "Events";
      break;
    case "/attendance":
      name = "Attendance";
      break;
    case "/exams":
      name = "Exams";
      break;
    default:
      name = "Dashboard";
  }

  const menuId = "primary-search-account-menu";
  const notificationsMenuId = "primary-notifications-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link style={{ textDecoration: "none", color: "black" }} to={"/settings"}>
        <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/support"}>
        <MenuItem onClick={handleMenuClose}>Support</MenuItem>
      </Link>

      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/feedback_elemzy"}
      >
        <MenuItem onClick={handleMenuClose}>Feedback</MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/error_elemzy"}
      >
        <MenuItem onClick={handleMenuClose}>Report Error</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/logout"}>
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </Link>
    </Menu>
  );

  const renderNotifications = (
    <Menu
      anchorEl={anchorE2}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={notificationsMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isNotificationsOpen}
      onClose={handleNotificationsClose}
    >
      {state.auth.unseenNotifications.map((notification, index) => (
        <div>
          {notification["seen"] == false ? (
            <MenuItem
              style={{
                width: "500px",
                height: "60px",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                updateNotificationSeen(notification["id"]);
                handleNotificationsClose();

                navigate(
                  "/" + notification["page"] + "?tab=" + notification["tab"]
                );
              }}
              sx={{
                "&:hover, &.Mui-focusVisible": {
                  backgroundColor: "#d7ecff",
                },
              }}
            >
              <span>
                <span
                  style={{
                    marginTop: "-20px",
                    fontWeight: "bold",
                    fontFamily: "Lato-Regular",
                    color: "#3182bd",
                  }}
                >
                  {notification["title"]}
                </span>
                <div style={{ color: "black", marginTop: "-5px" }}>
                  {notification["body"]}
                </div>
              </span>
            </MenuItem>
          ) : (
            ""
          )}
        </div>
      ))}
      {state.auth.unseenNotifications.length == 0 ? (
        <MenuItem
          style={{
            width: "500px",
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>
            <span style={{ color: "grey", textAlign: "left" }}>
              No new notifications.
            </span>
          </span>
          <EmojiEmotionsIcon style={{ color: "#FFD960" }} />
        </MenuItem>
      ) : (
        ""
      )}
      <br />
      <Link
        style={{ textDecoration: "none", color: "#3182bd" }}
        to="/notifications"
      >
        <MenuItem
          style={{
            width: "500px",
            height: "40px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex" }}>
            <span style={{ color: "#3182bd", textAlign: "right" }}>
              See all notifications
            </span>
            <ArrowForwardIosIcon
              style={{
                marginTop: "-3px",
                color: "#3182bd",
                marginLeft: "15px",
              }}
            />
          </div>
        </MenuItem>
      </Link>

      <MenuItem
        onClick={() => clearAllNotifications()}
        style={{
          width: "500px",
          height: "40px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ display: "flex" }}>
          <span style={{ color: "#ff3333", textAlign: "right" }}>
            Clear all notifications
          </span>
          <DeleteIcon
            style={{
              marginTop: "-3px",
              color: "#ff3333",
              marginLeft: "15px",
            }}
          />
        </div>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem onClick={handleNotificationsMenuOpen}>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge
            badgeContent={state.auth.unseenNotifications.length}
            color="error"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  if (state.auth.alert) {
    setSnack2({
      title: state.auth.alert.title,
      message: state.auth.alert.message,
      type: state.auth.alert.type,
    });
    setShowSnack2(true);
    dispatch({ type: "REMOVE_ALERT" });
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (!state.auth.isSocketLoading) {
        state.auth.client.onopen = () => {
          console.log("socket");
        };
        state.auth.client.onmessage = (message) => {
          dispatch(getNotifications());
          var client_message = JSON.parse(message.data);
          console.log(client_message);
          var type = client_message["type"];
          if (type == "text" || type == "attachment") {
            dispatch({ type: "TEXT_MESSAGE", payload: client_message });

            if (window.location.pathname != "/messenger") {
              setSnack({
                title: "New Message!",
                message: client_message["message"],
              });
              setShowSnack(true);
              playAudio();
            }
          }

          if (type == "notification") {
            playAudio();
            setSnack({
              title: client_message["title"],
              message: client_message["message"],
            });
            setShowSnack(true);
            playAudio();
          }

          if (type == "refresh") {
            if (window.location.pathname == "/" + client_message["page"]) {
              dispatch({ type: "REFRESH_DATA", payload: "REFRESH_DATA" });
            }
          }
        };
      }
      return (
        <div style={{ zIndex: "99" }}>
          <CssBaseline />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openFeedback}
            onClose={() => setOpenFeedback(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openFeedback}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  backgroundColor: "white",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  padding: "20px",
                  borderRadius: "6px",
                }}
              >
                <div style={{ marginTop: "-25px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton size="small">
                      <CloseIcon
                        onClick={() => setOpenFeedback(false)}
                        style={{ color: "#3182bd" }}
                      />
                    </IconButton>
                  </div>
                  <h3>Welcome to Elemzy</h3>
                  <p>
                    Elemzy, being in the beta stage, may contain bugs or errors.
                    We value your feedback to enhance the user experience
                    <br></br>
                    <br></br>
                  </p>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={"/feedback_elemzy"}
                    >
                      <Button
                        onClick={() => {
                          setOpenFeedback(false);
                        }}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        Give Feedback
                      </Button>
                    </Link>
                    <Button
                      style={{ height: "30px", marginLeft: "10px" }}
                      variant="contained"
                      onClick={() => setOpenFeedback(false)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openError}
            onClose={() => setOpenError(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openError}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  backgroundColor: "white",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  padding: "20px",
                  borderRadius: "6px",
                }}
              >
                <div style={{ marginTop: "-25px" }}>
                  <div
                    style={{
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <IconButton size="small">
                      <CloseIcon
                        onClick={() => setOpenError(false)}
                        style={{ color: "#3182bd" }}
                      />
                    </IconButton>
                  </div>
                  <h3>Welcome to Elemzy</h3>
                  <p>
                    Elemzy, being in the beta stage, may contain bugs or errors.
                    We value your feedback to enhance the user experience
                    <br></br>
                    Please report any errors that you might have experienced.
                    <br></br>
                    <br></br>
                  </p>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link
                      style={{ textDecoration: "none", color: "black" }}
                      to={"/error_elemzy"}
                    >
                      <Button
                        onClick={() => {
                          setOpenError(false);
                        }}
                        style={{ height: "30px" }}
                        variant="contained"
                      >
                        Report Errors
                      </Button>
                    </Link>
                    <Button
                      style={{ height: "30px", marginLeft: "10px" }}
                      variant="contained"
                      onClick={() => setOpenError(false)}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            autoHideDuration={6000}
            open={snackState}
            onClose={() => setShowSnack(false)}
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
          >
            <Card style={{ minWidth: "350px", padding: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <NotificationsIcon
                    style={{ color: "#3182bd", fontSize: "44px" }}
                  />
                  <div style={{ marginLeft: "10px" }}>
                    <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                    <br></br>
                    <span>{snack["message"]}</span>
                  </div>
                </div>
                <IconButton size="small">
                  <CloseIcon
                    onClick={() => setShowSnack(false)}
                    style={{ color: "#3182bd" }}
                  />
                </IconButton>
              </div>
            </Card>
          </Snackbar>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={6000}
            open={showSnack2}
            onClose={() => setShowSnack2(false)}
          >
            <Card style={{ minWidth: "350px", padding: "10px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  {snack2["type"] == "SUCCESS" ? (
                    <CheckCircleIcon
                      style={{ color: "#2ca25f", fontSize: "44px" }}
                    />
                  ) : snack2["type"] == "MESSAGE" ? (
                    <ErrorIcon style={{ color: "#3182bd", fontSize: "44px" }} />
                  ) : (
                    <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
                  )}
                  <div style={{ marginLeft: "10px" }}>
                    <b style={{ fontSize: "15px" }}>{snack2["title"]}</b>
                    <br></br>
                    <span>{snack2["message"]}</span>
                  </div>
                </div>
                <IconButton size="small">
                  <CloseIcon
                    onClick={() => setShowSnack2(false)}
                    style={{ color: "#3182bd" }}
                  />
                </IconButton>
              </div>
            </Card>
          </Snackbar>
          <AppBar elevation={0} position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: 5,
                  marginLeft: "-8px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {open ? (
                <Fragment></Fragment>
              ) : (
                <Divider
                  orientation="vertical"
                  style={{
                    minHeight: "inherit",
                    color: "red",
                    width: "1px",
                    marginLeft: "-24px",
                  }}
                />
              )}
              <span
                style={{
                  marginLeft: "16px",
                  fontSize: "25px",
                  marginTop: "3px",
                  fontFamily: "Bahnschrift",
                }}
              >
                {name}
              </span>{" "}
              <Box sx={{ flexGrow: 1 }} />
              <div style={{ margin: "10px 17px 10px 10px", width: "50%" }}>
                {props.tabs}
              </div>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton> */}
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                  aria-controls={notificationsMenuId}
                  aria-haspopup="true"
                  onClick={handleNotificationsMenuOpen}
                >
                  <Badge
                    badgeContent={state.auth.unseenNotifications.length}
                    color="error"
                  >
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <Tooltip
                  arrow
                  title={
                    state.auth.user.firstname + " " + state.auth.user.lastname
                  }
                  placement="right"
                >
                  <IconButton
                    size="large"
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={menuId}
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt={
                        state.auth.user.firstname +
                        " " +
                        state.auth.user.lastname
                      }
                      src={API_URL + "/media/" + state.auth.user.user_image}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <Tooltip
                  arrow
                  title={
                    state.auth.user.firstname + " " + state.auth.user.lastname
                  }
                  placement="right"
                >
                  <IconButton
                    size="large"
                    aria-label="show more"
                    aria-controls={mobileMenuId}
                    aria-haspopup="true"
                    onClick={handleMobileMenuOpen}
                    color="inherit"
                  >
                    <Avatar
                      alt={
                        state.auth.user.firstname +
                        " " +
                        state.auth.user.lastname
                      }
                      src={API_URL + "/media/" + state.auth.user.user_image}
                    />{" "}
                  </IconButton>
                </Tooltip>
              </Box>
            </Toolbar>
          </AppBar>
          {renderMobileMenu}
          {renderMenu}
          {renderNotifications}
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <a
                style={{ textDecoration: "none" }}
                href={"https://teacher.elemzy.com"}
              >
                <img
                  style={{
                    maxHeight: "50px",
                    marginTop: "5px",
                    marginRight: "15px",
                  }}
                  src={require("../img/appbar.png")}
                />
              </a>
              <IconButton
                style={{
                  color: "#084594",
                  fontSize: "20px",
                  fontWeight: "800",
                  marginLeft: "-1px",
                }}
                onClick={handleDrawerClose}
              >
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>

            <List
              component="nav"
              aria-label="main mailbox folders"
              style={{ marginTop: "-8px" }}
            >
              <Link to="/dashboard" path>
                <Tooltip placement="right" arrow title="Dashboard">
                  <ListItem
                    button
                    key="dashboard"
                    style={
                      window.location.pathname === "/" ||
                      window.location.pathname === "/dashboard"
                        ? { backgroundColor: "#3182bd" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <DashboardIcon
                        style={
                          window.location.pathname === "/" ||
                          window.location.pathname === "/dashboard"
                            ? {
                                marginLeft: "8px",
                                marginRight: "30px",
                                color: "white",
                              }
                            : { marginLeft: "8px", marginRight: "30px" }
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        window.location.pathname === "/" ||
                        window.location.pathname === "/dashboard"
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                      primary="Dashboard"
                    />
                  </ListItem>
                </Tooltip>
              </Link>
              <Link to="/classes">
                <Tooltip placement="right" arrow title="Classes">
                  <ListItem
                    button
                    key="classes"
                    style={
                      window.location.pathname === "/classes"
                        ? { backgroundColor: "#3182bd" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <ClassIcon
                        style={
                          window.location.pathname === "/classes"
                            ? {
                                marginLeft: "8px",
                                marginRight: "30px",
                                color: "white",
                              }
                            : { marginLeft: "8px", marginRight: "30px" }
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        window.location.pathname === "/classes"
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                      primary="Classes"
                    />
                  </ListItem>
                </Tooltip>
              </Link>
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/attendance">
                  <Tooltip placement="right" arrow title="Attendance">
                    <ListItem
                      button
                      key="attendance"
                      style={
                        window.location.pathname === "/attendance"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <AssignmentTurnedInIcon
                          style={
                            window.location.pathname === "/attendance"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/attendance"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Attendance"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/events" path>
                  <Tooltip placement="right" arrow title="Events">
                    <ListItem
                      button
                      key="events"
                      style={
                        window.location.pathname === "/" ||
                        window.location.pathname === "/events"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <DateRangeIcon
                          style={
                            window.location.pathname === "/" ||
                            window.location.pathname === "/events"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/" ||
                          window.location.pathname === "/events"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Events"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
              <Link to="/exams">
                <Tooltip placement="right" arrow title="Exams">
                  <ListItem
                    button
                    key="exams"
                    style={
                      window.location.pathname === "/exams"
                        ? { backgroundColor: "#3182bd" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <WorkspacesIcon
                        style={
                          window.location.pathname === "/exams"
                            ? {
                                marginLeft: "8px",
                                marginRight: "30px",
                                color: "white",
                              }
                            : { marginLeft: "8px", marginRight: "30px" }
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        window.location.pathname === "/exams"
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                      primary="Exams"
                    />
                  </ListItem>
                </Tooltip>
              </Link>
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/results">
                  <Tooltip placement="right" arrow title="Results">
                    <ListItem
                      button
                      key="results"
                      style={
                        window.location.pathname === "/results"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <SchoolIcon
                          style={
                            window.location.pathname === "/results"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/results"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Results"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/meeting">
                  <Tooltip placement="right" arrow title="Meeting">
                    <ListItem
                      button
                      key="meeting"
                      style={
                        window.location.pathname === "/meeting"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <VideocamIcon
                          style={
                            window.location.pathname === "/meeting"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/meeting"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Meeting"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/ranking">
                  <Tooltip placement="right" arrow title="Ranking">
                    <ListItem
                      button
                      key="ranking"
                      style={
                        window.location.pathname === "/ranking"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <TrendingUpIcon
                          style={
                            window.location.pathname === "/ranking"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/ranking"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Ranking"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
              <Link to="/approvals">
                <Tooltip placement="right" arrow title="Approvals">
                  <ListItem
                    button
                    key="approvals"
                    style={
                      window.location.pathname === "/approvals"
                        ? { backgroundColor: "#3182bd" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <FormatListNumberedIcon
                        style={
                          window.location.pathname === "/approvals"
                            ? {
                                marginLeft: "8px",
                                marginRight: "30px",
                                color: "white",
                              }
                            : { marginLeft: "8px", marginRight: "30px" }
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        window.location.pathname === "/approvals"
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                      primary="Approvals"
                    />
                  </ListItem>
                </Tooltip>
              </Link>

              <Link to="/feedback">
                <Tooltip placement="right" arrow title="Feedback">
                  <ListItem
                    button
                    key="feedback"
                    style={
                      window.location.pathname === "/feedback"
                        ? { backgroundColor: "#3182bd" }
                        : {}
                    }
                  >
                    <ListItemIcon>
                      <ThumbsUpDownIcon
                        style={
                          window.location.pathname === "/feedback"
                            ? {
                                marginLeft: "8px",
                                marginRight: "30px",
                                color: "white",
                              }
                            : { marginLeft: "8px", marginRight: "30px" }
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        window.location.pathname === "/feedback"
                          ? {
                              color: "white",
                            }
                          : {}
                      }
                      primary="Feedback"
                    />
                  </ListItem>
                </Tooltip>
              </Link>
              {state.auth.tier == "PRO" || state.auth.tier == "TRIAL" ? (
                <Link to="/messenger">
                  <Tooltip placement="right" arrow title="Messenger">
                    <ListItem
                      button
                      key="messenger"
                      style={
                        window.location.pathname === "/messenger"
                          ? { backgroundColor: "#3182bd" }
                          : {}
                      }
                    >
                      <ListItemIcon>
                        <MessageIcon
                          style={
                            window.location.pathname === "/messenger"
                              ? {
                                  marginLeft: "8px",
                                  marginRight: "30px",
                                  color: "white",
                                }
                              : { marginLeft: "8px", marginRight: "30px" }
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        style={
                          window.location.pathname === "/messenger"
                            ? {
                                color: "white",
                              }
                            : {}
                        }
                        primary="Messenger"
                      />
                    </ListItem>
                  </Tooltip>
                </Link>
              ) : (
                ""
              )}
            </List>
          </Drawer>
          {renderNotifications}
          {renderMenu}
        </div>
      );
    } else {
      return <Fragment></Fragment>;
    }
  } else {
    return <Fragment></Fragment>;
  }
}

export default Sidebar;
