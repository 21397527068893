import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Ranking from "./pages/Ranking";
import Events from "./pages/Events";
import Results from "./pages/Results";
import Classes from "./pages/Classes";
import Support from "./pages/Support";
import Messenger from "./pages/Messenger";
import Settings from "./pages/Settings";
import Meeting from "./pages/Meeting";
import Attendance from "./pages/Attendance";
import Exams from "./pages/Exams";
import Feedback from "./pages/Feedback";
import Logout from "./pages/Logout";
import Notifications from "./pages/Notifications";
import Expired from "./pages/Expired";
import Oops from "./pages/Oops";
import Approvals from "./pages/Approvals";
import Initialize from "./pages/Initialize";
import FeedbackForm from "./pages/FeedbackForm";
import ErrorReport from "./pages/ErrorReport";

import Loader from "./pages/Loader";

import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "./actions/auth";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
  }, []);

  if (state.auth.loginFailed) {
    if (window.location.pathname !== "/initialize") {
      navigate("/login", { replace: true });
      dispatch({ type: "RESET_REDIRECT_LOGIN" });
    }
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/meeting" element={<Meeting />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<Forgot />} />
      <Route path="/classes" element={<Classes />} />
      <Route path="/ranking" element={<Ranking />} />
      <Route path="/events" element={<Events />} />
      <Route path="/results" element={<Results />} />
      <Route path="/messenger" element={<Messenger />} />
      <Route path="/support" element={<Support />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/attendance" element={<Attendance />} />
      <Route path="/exams" element={<Exams />} />{" "}
      <Route path="/logout" element={<Logout />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/expired" element={<Expired />} />
      <Route path="/feedback_elemzy" element={<FeedbackForm />} />
      <Route path="/error_elemzy" element={<ErrorReport />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/initialize" element={<Initialize />} />
      <Route path="/approvals" element={<Approvals />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;
