import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import PublicIcon from "@mui/icons-material/Public";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { Link, Navigate, useNavigate } from "react-router-dom";
import CalculateIcon from "@mui/icons-material/Calculate";
import { Chart } from "chart.js/auto";
import Calendar from "react-calendar";
import moment from "moment";
import { CardActionArea } from "@mui/material";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

function getIcon(subject) {
  var sub = subject.toLowerCase();
  if (sub.includes("break") || sub.includes("lunch")) {
    return <LocalDiningIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("mechanic")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("social")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("civics")) {
    return <GavelIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("polit")) {
    return <BallotIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("compu")) {
    return <ComputerIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("bio")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("chem") || sub.includes("scien")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("economics") ||
    sub.includes("accou") ||
    sub.includes("busi")
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("geom") ||
    sub.includes("archi") ||
    (sub.includes("engg") && sub.includes("draw"))
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("geo")) {
    return <PublicIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("math")) {
    return <CalculateIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("history")) {
    return <HistoryIcon style={{ fontSize: 45, color: "black" }} />;
  } else {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  }
}

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDay, setSelectedDay] = useState(weekday[new Date().getDay()]);
  const [isLoading, setIsLoading] = useState(true);
  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDay(weekday[date.getDay()]);
  };
  const [studentLeaveRequests, setStudentLeaveRequests] = useState([]);

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const graph = {
    dataLine: {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Students failed",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "red",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(0, 0, 0)",
          pointHoverBorderColor: "rgba(220, 220, 220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 5,
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    },
  };
  const [rankersList, setRankersList] = useState([]);
  const [importantDatesList, setimportantDatesList] = useState([]);
  const [getFunction, setGetFunction] = useState(false);
  const [holidayList, setHolidayList] = useState([]);
  const [getTimeTable, setGetTimeTable] = useState(false);
  const [timeTable, setTimeTable] = useState({});
  const [eventsList, setEventsList] = useState([]);
  const [assignmentList, setAssignmentList] = useState([]);
  const [importantDatesDateList, setimportantDatesDateList] = useState([]);
  const [holidayDateList, setHolidayDateList] = useState([]);
  const [eventsDateList, setEventsDateList] = useState([]);
  const [examDateList, setExamDateList] = useState([]);
  const [exams, setExams] = useState([]);

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [noticeBoard, setNoticeBoard] = useState({});

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_events_list", body, config).then((res) => {
      setHolidayList(res.data["holidays_list"]);
      setimportantDatesList(res.data["important_dates_list"]);
      setTimeTable(JSON.parse(state.auth.user.time_table));

      var tempEventDateList = [];
      var tempHolidayDateList = [];
      var tempImportantDateList = [];
      var tempEventsList = res.data["events_list"];
      for (var i = res.data["events_list"].length - 1; i >= 0; i--) {
        if (
          moment(res.data["events_list"][i]["date"], "DD-MM-YYYY").isBefore(
            moment()
          )
        ) {
          tempEventsList.splice(i, 1);
        }
      }
      setEventsList(tempEventsList);
      for (var i = 0; i < res.data["events_list"].length; i++) {
        tempEventDateList.push(res.data["events_list"][i]["date"]);
      }

      for (var i = 0; i < res.data["holidays_list"].length; i++) {
        tempHolidayDateList.push(res.data["holidays_list"][i]["date"]);
      }

      for (var i = 0; i < res.data["important_dates_list"].length; i++) {
        tempImportantDateList.push(res.data["important_dates_list"][i]["date"]);
      }

      setEventsDateList(tempEventDateList);
      setimportantDatesDateList(tempImportantDateList);
      setHolidayDateList(tempHolidayDateList);

      axios
        .post(API_URL + "/get_assignment_list_teacher", body, config)
        .then((res) => {
          var data = res.data;
          data.splice(4, data.length);
          setAssignmentList(data);
          axios
            .post(API_URL + "/get_exams_teacher_dashboard", body, config)
            .then((res) => {
              var tempExamDateList = [];
              var tempExamList = [];

              for (var i = 0; i < res.data.length; i++) {
                if (
                  !res.data[i]["completed"] &&
                  moment(res.data[i]["start_date"], "DD-MM-YYYY").isAfter(
                    moment()
                  )
                ) {
                  tempExamList.push(res.data[i]);
                }
              }

              for (var i = 0; i < tempExamList.length; i++) {
                for (var j = 0; j < tempExamList[i]["subjects"].length; j++) {
                  var tempSub = tempExamList[i]["subjects"][j];
                  tempExamDateList.push(
                    tempSub[Object.keys(tempSub)[0]]["date"]
                  );
                }
              }
              setExams(tempExamList);
              setExamDateList(tempExamDateList);
              axios
                .post(API_URL + "/get_leave_requests_principal", body, config)
                .then((res) => {
                  var studentpending = 0;
                  var studentapproved = 0;
                  var studentrejected = 0;

                  for (
                    var i = 0;
                    i < res.data["student_requests"].length;
                    i++
                  ) {
                    if (
                      res.data["student_requests"][i]["status"] == "PENDING"
                    ) {
                      studentpending += 1;
                    } else if (
                      res.data["student_requests"][i]["status"] == "APPROVED"
                    ) {
                      studentapproved += 1;
                    } else if (
                      res.data["student_requests"][i]["status"] == "REJECTED"
                    ) {
                      studentrejected += 1;
                    }
                  }
                  setStudentLeaveRequests({
                    PENDING: studentpending,
                    APPROVED: studentapproved,
                    REJECTED: studentrejected,
                  });
                  axios
                    .post(API_URL + "/get_noticeboard_teacher", body, config)
                    .then((res) => {
                      setNoticeBoard(res.data);
                      setIsLoading(false);
                    });
                });
            })
            .catch((err) => {});
        });
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  var colorIndex = 0;
  var today = new Date();

  function getContent() {
    if (selectedPage == "HOME") {
      return (
        <Fade in={true}>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <h2>Timetable</h2>
              {Object.keys(timeTable).length != 0 ? (
                <div style={{ marginTop: "-18px" }}>
                  {timeTable[selectedDay] == "" ? (
                    <Box
                      borderRadius="6px"
                      style={{
                        backgroundColor: colorList[colorIndex],
                        borderBottom: "4px solid " + colorList2[colorIndex],
                        width: "100%",
                        padding: "2px",
                        height: "85vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundImage:
                          "linear-gradient(to right, rgba(251,237,217, 0.9), rgba(251,237,217, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
                      }}
                    >
                      <Fab
                        size="large"
                        style={{
                          height: "100px",
                          width: "100px",
                          border: "5px solid #31a354",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <CheckIcon
                          style={{
                            color: "#31a354",
                            fontSize: "75px",
                          }}
                        />
                      </Fab>
                      <h4 style={{ color: "black" }}>No Lectures Today!</h4>
                      <div
                        style={{
                          margin: "-20px 0px 0px 12px",
                          color: "grey",
                          fontSize: "15px",
                          fontFamily: "Bahnschrift",
                        }}
                      >
                        There are no lectures scheduled for today
                      </div>
                    </Box>
                  ) : (
                    <Fragment>
                      {timeTable[selectedDay].map((lecture, index) => (
                        <div style={{ marginBottom: "15px" }}>
                          <Grid
                            container
                            spacing={0}
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid md={2}>
                              <p
                                style={{
                                  fontSize: "15px",
                                  marginTop: "12px",
                                  fontFamily: "Lato-Regular",
                                  color: "grey",
                                }}
                              >
                                {Object.keys(lecture)[0]}
                                <span style={{ display: "none" }}>
                                  {
                                    (colorIndex = Math.floor(
                                      Math.random() * colorList.length
                                    ))
                                  }
                                </span>
                              </p>
                            </Grid>
                            <Grid item md={10}>
                              <Box
                                height={60}
                                borderRadius="10px"
                                style={
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("break") ||
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("lunch")
                                    ? {
                                        border: "1.5px solid grey",
                                        padding: "2px",
                                        display: "flex",
                                      }
                                    : {
                                        padding: "2px",
                                        display: "flex",
                                        backgroundColor: colorList[colorIndex],
                                      }
                                }
                              >
                                <div
                                  style={{
                                    margin:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "4px 0px 0px 8px"
                                        : "6px 0px 0px 8px",
                                    color: colorList2[colorIndex],
                                  }}
                                >
                                  {getIcon(Object.values(lecture)[0])}
                                </div>
                                <div>
                                  <div
                                    style={{
                                      fontFamily: "Lato-Bold",
                                      fontWeight: "600",
                                      letterSspacing: "2px",

                                      margin:
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("break") ||
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("lunch")
                                          ? "1px 0px 0px 12px"
                                          : "3px 0px 0px 12px",
                                      color:
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("break") ||
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("lunch")
                                          ? "black"
                                          : colorList2[colorIndex],
                                      fontSize: "20px",
                                    }}
                                  >
                                    {Object.values(lecture)[0]}
                                  </div>
                                  <div
                                    style={{
                                      margin: "-3px 0px 0px 12px",
                                      color:
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("break") ||
                                        Object.values(lecture)[0]
                                          .toLowerCase()
                                          .includes("lunch")
                                          ? "black"
                                          : colorList2[colorIndex],
                                      fontSize: "15px",
                                    }}
                                  >
                                    {Object.values(lecture)[1]}
                                  </div>
                                </div>
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                    </Fragment>
                  )}
                  <br></br>
                </div>
              ) : (
                ""
              )}
            </Grid>
            <Grid item md={3} style={{ height: "90vh" }}>
              <h2>Calendar</h2>
              <div style={{ marginTop: "-35px", marginBottom: "15px" }}>
                <Box
                  borderRadius="16px"
                  style={{
                    backgroundColor: colorList[colorIndex],
                    marginTop: "40px",
                  }}
                >
                  <div className="calendar-container">
                    <Calendar
                      style={{
                        width: "100%",
                        backgroundColor: "black",
                      }}
                      onChange={handleDateChange}
                      value={selectedDate}
                      tileClassName={({ date, view }) => {
                        if (
                          eventsDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightEvent";
                        } else if (
                          holidayDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightHoliday";
                        } else if (
                          importantDatesDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightImportant";
                        } else if (
                          examDateList.find(
                            (x) => x === moment(date).format("DD-MM-YYYY")
                          )
                        ) {
                          return "highlightExam";
                        } else if (date.getDay() == 6) {
                          return "highlightSaturday";
                        }
                      }}
                    />
                  </div>
                </Box>
                <div
                  style={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    padding: 12,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#6a6fff",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    Assignment
                  </span>
                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#a1d99b",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    Exam
                  </span>

                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "red",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    Holiday
                  </span>

                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#fa9fb5",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    Event
                  </span>

                  <span
                    style={{
                      marginRight: "4px",
                      color: "white",
                      fontSize: "13px",
                      backgroundColor: "#fdbb84",
                      padding: "5px",
                      borderRadius: "20px",
                    }}
                  >
                    {" "}
                    Important
                  </span>
                </div>
              </div>
              {eventsList.length != 0 ? (
                <h2 style={{ marginTop: "-15px" }}>Upcoming events</h2>
              ) : (
                <Fragment>
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#f0f3f7",
                        borderRadius: "15px",
                        alignItems: "center",
                        width: "100%",
                        height: "33vh",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            height: "100px",
                            width: "100px",
                            border: "5px solid #31a354",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <CheckIcon
                            style={{
                              color: "#31a354",
                              fontSize: "75px",
                            }}
                          />
                        </Fab>
                        <h4 style={{ color: "black" }}>No upcoming Events.</h4>
                        <div
                          style={{
                            color: "grey",
                            marginTop: "-25px",
                            fontFamily: "bahnschrift",
                          }}
                        >
                          Click Here to go to events page.
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fragment>
              )}

              <div style={{ marginTop: "-10px" }}>
                {eventsList.slice(0, 2).map((event, index) => (
                  <Link
                    to="/events"
                    path
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <span style={{ display: "none" }}>
                      {
                        (colorIndex = Math.floor(
                          Math.random() * colorList.length
                        ))
                      }
                    </span>
                    <CardActionArea
                      style={{
                        height: "80px",
                        borderLeft: "5px solid " + colorList3[colorIndex],
                        borderRadius: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          md={8}
                          style={{
                            marginTop: "-3px",
                            marginLeft: "5px",
                          }}
                        >
                          <p
                            style={{
                              margin: "0px 0px 3px 10px",
                              fontFamily: "Lato-Bold",
                              fontSize: "16px",
                            }}
                          >
                            {event["name"]}
                          </p>

                          <p
                            style={{
                              margin: "0px 0px 5px 10px",
                              fontSize: "13px",
                            }}
                          >
                            {moment(event["date"], "DD-MM-YYYY").format(
                              "Do MMMM, YYYY"
                            )}
                          </p>
                          <p
                            style={{
                              margin: "-3px 0px 0px 10px",
                              fontSize: "15px",
                              color: colorList2[colorIndex],
                            }}
                          >
                            {event["venue"]}
                          </p>
                        </Grid>
                        <Grid item>
                          <ChevronRightIcon
                            style={{
                              fontSize: "40px",
                              color: colorList3[colorIndex],
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Link>
                ))}
                {eventsList.length >= 2 ? (
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "-20px",
                        width: "100%",
                        textAlign: "right",
                        color: "#3182bd",
                      }}
                    >
                      <h4>More Events...</h4>
                    </div>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid item md={3} style={{ width: "100%" }}>
              <h2>Student Leave Requests</h2>
              <div
                style={{
                  display: "flex",
                  marginTop: "17px",
                  justifyContent: "space-between",

                  width: "100%",
                }}
              >
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#31a354",
                      color: "white",
                      boxShadow: "none",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "50px",
                        marginTop: "30px",
                        textAlign: "center",
                      }}
                    >
                      {studentLeaveRequests["APPROVED"]}
                    </h1>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginTop: "10px",
                      color: "#31a354",
                      fontFamily: "Bahnschrift",
                      width: "100%",
                    }}
                  >
                    APPROVED{" "}
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#ff3333",
                      color: "white",
                      boxShadow: "none",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <h1 style={{ fontSize: "50px" }}>
                      {studentLeaveRequests["REJECTED"]}
                    </h1>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginTop: "10px",
                      color: "#ff3333",
                      fontFamily: "Bahnschrift",
                    }}
                  >
                    REJECTED
                  </div>
                </Link>
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#3182bd",
                      color: "white",
                      boxShadow: "none",
                      height: "100px",
                      width: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                    }}
                  >
                    <h1 style={{ fontSize: "50px" }}>
                      {studentLeaveRequests["PENDING"]}
                    </h1>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      marginTop: "10px",
                      color: "#3182bd",
                      fontFamily: "Bahnschrift",
                    }}
                  >
                    PENDING
                  </div>
                </Link>
              </div>

              <h2>Submission Status</h2>
              {assignmentList.length == 0 ? (
                <Fragment>
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#fbedd9",
                        borderRadius: "15px",
                        alignItems: "center",
                        height: "59%",
                        borderBottom: "4px solid " + colorList2[colorIndex],
                        backgroundImage:
                          "linear-gradient(to right, rgba(237,248,233, 0.9), rgba(237,248,233, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            height: "100px",
                            width: "100px",
                            border: "5px solid #31a354",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <CheckIcon
                            style={{
                              color: "#31a354",
                              fontSize: "75px",
                            }}
                          />
                        </Fab>
                        <h4 style={{ color: "black" }}>
                          No pending submissions.
                        </h4>
                        <div
                          style={{
                            color: "grey",
                            marginTop: "-25px",
                            fontFamily: "bahnschrift",
                          }}
                        >
                          Click Here to go to events page.
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fragment>
              ) : (
                ""
              )}
              {assignmentList.slice(0, 5).map((assignment, index) => (
                <div>
                  <span style={{ display: "none" }}>
                    {
                      (colorIndex = Math.floor(
                        Math.random() * colorList.length
                      ))
                    }
                  </span>
                  <span>
                    {assignment["class_id"]} {assignment["division"]}
                    {" - "}
                    {assignment["subject"]}
                  </span>
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ marginBottom: "10px" }}
                  >
                    <Box
                      mr={1}
                      sx={{
                        width: "100%",
                        color: colorList2[colorIndex],
                      }}
                    >
                      {assignment["total_students"] == 0 ? (
                        <div
                          style={{
                            fontSize: "13px",
                            color: colorList2[colorIndex],
                          }}
                        >
                          No Students in{" "}
                          {" " +
                            assignment["class_id"] +
                            " " +
                            assignment["division"]}
                        </div>
                      ) : (
                        <LinearProgress
                          variant="determinate"
                          color="inherit"
                          style={{
                            height: "10px",
                            borderRadius: "5px",
                          }}
                          value={
                            (assignment["submitted"] /
                              assignment["total_students"]) *
                            100
                          }
                        />
                      )}
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="textSecondary">
                        {(assignment["submitted"] /
                          assignment["total_students"]) *
                          100}
                        %
                      </Typography>
                    </Box>
                  </Box>
                </div>
              ))}
            </Grid>
            <Grid item md={3}>
              {exams.length != 0 ? (
                <h2>Upcoming Examinations</h2>
              ) : (
                <Fragment>
                  <h2>No Upcoming Examinations</h2>
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#f0f3f7",
                        borderRadius: "15px",
                        alignItems: "center",
                        height: "50%",
                        borderBottom: "4px solid " + colorList2[colorIndex],
                        backgroundImage:
                          "linear-gradient(to right, rgba(215,236,255, 0.9), rgba(215,236,255, 0.9)), url(https://img.freepik.com/free-vector/education-pattern-background-doodle-style_53876-115365.jpg?w=740)",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            height: "100px",
                            width: "100px",
                            border: "5px solid #31a354",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <CheckIcon
                            style={{
                              color: "#31a354",
                              fontSize: "75px",
                            }}
                          />
                        </Fab>
                        <h4 style={{ color: "black" }}>
                          No upcoming Examinations.
                        </h4>
                        <div
                          style={{
                            color: "grey",
                            marginTop: "-25px",
                            fontFamily: "bahnschrift",
                          }}
                        >
                          Click Here to go to events page.
                        </div>
                      </div>
                    </div>
                  </Link>
                </Fragment>
              )}
              <div style={{ marginTop: "-15px" }}>
                {exams.slice(0, 5).map((exam, index) => (
                  <Link to="/exams" style={{ textDecoration: "none" }}>
                    <span style={{ display: "none" }}>
                      {
                        (colorIndex = Math.floor(
                          Math.random() * colorList.length
                        ))
                      }
                    </span>

                    <CardActionArea
                      fullWidth
                      style={{
                        borderRadius: "15px",
                        padding: "12px 10px 10px 8px",
                        backgroundColor: colorList[colorIndex],
                        marginBottom: "15px",
                      }}
                    >
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid
                          item
                          md={8}
                          style={{
                            marginTop: "-3px",
                            marginLeft: "5px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "2px",
                              fontSize: "16px",
                              fontFamily: "Lato-Bold",
                              color: colorList2[colorIndex],
                            }}
                          >
                            {exam["test"]}
                          </div>
                          <div
                            style={{
                              marginBottom: "1px",
                              fontSize: "13px",

                              color: colorList2[colorIndex],
                            }}
                          >
                            {exam["semester"]}
                          </div>
                          <span
                            style={{
                              fontSize: "13px",
                              fontFamily: "Lato-Regular",
                              color: "black",
                            }}
                          >
                            {moment(exam["start_date"], "DD-MM-YYYY").format(
                              "Do MMMM, YYYY"
                            ) +
                              " - " +
                              moment(exam["end_date"], "DD-MM-YYYY").format(
                                "Do MMMM, YYYY"
                              )}
                          </span>
                        </Grid>
                        <Grid item>
                          <ChevronRightIcon
                            style={{
                              fontSize: "40px",
                              color: colorList3[colorIndex],
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Link>
                ))}
              </div>
              <br />
              <div style={{ marginTop: "-15px" }}>
                <h2>Noticeboard</h2>
                {noticeBoard[Object.keys(noticeBoard)[0]].length != 0 ? (
                  <Fragment>
                    <h4>
                      {Object.keys(noticeBoard)[0].charAt(0).toUpperCase() +
                        Object.keys(noticeBoard)[0].slice(1)}
                    </h4>
                    <Grid container spacing={1.5}>
                      {noticeBoard[Object.keys(noticeBoard)[0]].map(
                        (noti, index) => (
                          <Grid item md={12}>
                            <Box
                              padding={1}
                              borderRadius="5px"
                              sx={{ "&:hover": { opacity: "1 !important" } }}
                              style={{
                                borderRadius: "6px",
                                border: "4px solid #d7ecff",
                                backgroundColor: "white",
                                boxShadow: "none",
                                height: "100%",
                                marginBottom: "5px",
                              }}
                            >
                              <Box style={{ marginLeft: "5px" }}>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item md={10}>
                                    <div
                                      style={{
                                        fontFamily: "Lato-Bold",
                                        color: "#084594",
                                        fontSize: "19px",
                                      }}
                                    >
                                      {noti["title"]}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Lato-Regular",
                                      }}
                                    >
                                      {moment(
                                        noti["date"],
                                        "DD-MM-YYYY"
                                      ).format("Do MMMM, YYYY")}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Lato-Bold",
                                      }}
                                    >
                                      {noti["venue"]}
                                    </div>
                                    <div
                                      style={{
                                        margin: "10px 0px 0px 0px",
                                        fontSize: "15px",
                                        fontFamily: "Lato-Regular",
                                      }}
                                    >
                                      {noti["desc"]}
                                    </div>
                                    {Object.keys(noti).includes("link") ? (
                                      <a
                                        href={noti["link"]}
                                        target="_blank"
                                        style={{
                                          margin: "10px 0px 0px 0px",
                                          fontSize: "15px",
                                          fontFamily: "Lato-Regular",
                                        }}
                                      >
                                        View Document
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        )
                      )}
                    </Grid>
                  </Fragment>
                ) : (
                  <div>
                    Recent Notices from the noticeboard will appear here.
                    <br />
                    <br />
                    Greetings from Elemzy Recent Notices from the noticeboard
                    will appear here.
                    <br />
                    <br /> Have a great day ahead!
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Fade>
      );
    } else {
      return (
        <div style={{ marginTop: "75px" }}>
          {Object.keys(noticeBoard).map((category) => (
            <Fragment>
              {noticeBoard[category].length != 0 ? (
                <Fragment>
                  <h4>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </h4>
                  <Grid container spacing={1.5}>
                    {noticeBoard[category].map((noti, index) => (
                      <Grid item md={3}>
                        <Box
                          padding={1}
                          borderRadius="5px"
                          sx={{ "&:hover": { opacity: "1 !important" } }}
                          style={{
                            borderRadius: "6px",
                            border: "4px solid #d7ecff",
                            backgroundColor: "white",
                            boxShadow: "none",
                            height: "100%",
                            marginBottom: "5px",
                          }}
                        >
                          <Box style={{ marginLeft: "5px" }}>
                            <Grid
                              container
                              spacing={2}
                              justifyContent="space-between"
                            >
                              <Grid item md={10}>
                                <div
                                  style={{
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                    fontSize: "19px",
                                  }}
                                >
                                  {noti["title"]}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontFamily: "Lato-Regular",
                                  }}
                                >
                                  {moment(noti["date"], "DD-MM-YYYY").format(
                                    "Do MMMM, YYYY"
                                  )}
                                </div>
                                <div
                                  style={{
                                    fontSize: "15px",
                                    fontFamily: "Lato-Bold",
                                  }}
                                >
                                  {noti["venue"]}
                                </div>
                                <div
                                  style={{
                                    margin: "10px 0px 0px 0px",
                                    fontSize: "15px",
                                    fontFamily: "Lato-Regular",
                                  }}
                                >
                                  {noti["desc"]}
                                </div>
                                {Object.keys(noti).includes("link") ? (
                                  <a
                                    href={noti["link"]}
                                    target="_blank"
                                    style={{
                                      margin: "10px 0px 0px 0px",
                                      fontSize: "15px",
                                      fontFamily: "Lato-Regular",
                                    }}
                                  >
                                    View Document
                                  </a>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Fragment>
              ) : (
                <span>
                  <>
                    {category == "everyone" ||
                    category == "parents" ||
                    category == "students" ||
                    category == "teachers" ? (
                      <h4
                        style={{
                          color: "grey",
                          fontSize: 18,
                        }}
                      >
                        No Notice for {category}
                      </h4>
                    ) : (
                      <></>
                    )}
                  </>
                </span>
              )}
            </Fragment>
          ))}
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_teacher) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "HOME" ? (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "NOTICEBOARD" ? (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <div
                style={{
                  margin: "48px 20px 0px 90px",
                  backgroundColor: "white",
                }}
              >
                {getContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Teacher");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
