import { useState } from "react";
import Fade from "@mui/material/Fade";
import TextField from "@mui/material/TextField";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import { useSelector, useDispatch } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { API_URL, alertMessage } from "../actions/auth";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { login } from "../actions/auth";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Link, useNavigate } from "react-router-dom";
import { add } from "date-fns";

function Initialize() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [page, setPage] = useState("HELLO");
  const [institutionId, setInstitutionId] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [selectedState, setSelectedState] = useState("Maharashtra");
  const [selectedCity, setSelectedCity] = useState("Mumbai City");

  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");

  const [clearText, setClearText] = useState("");

  function createAlert(type, title, message) {
    dispatch(
      alertMessage(
        type,
        title,
        message.includes("DOCTYPE") ? "Internal Server Error" : message
      )
    );
  }

  if (state.auth.alert) {
    setSnack({
      title: state.auth.alert.title,
      message: state.auth.alert.message,
      type: state.auth.alert.type,
    });
    setShowSnack(true);
    dispatch({ type: "REMOVE_ALERT" });
  }

  if (state.auth.user) {
    if (state.auth.user.initiated == "APPROVED") {
      navigate("/dashboard");
    }
  }

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
    );
  };

  function registerUser() {
    if (institutionId.length == 0) {
      createAlert("ERROR", "Error", "Enter Institution ID to continue.");
    } else if (!validateEmail(email)) {
      createAlert("ERROR", "Error", "Enter valid Email ID");
    } else {
      if (password.length > 7 && password == password2) {
        var body = {};
        const config = {
          headers: {
            Authorization: state.auth.token,
            "X-CSRFToken": state.auth.csrfToken,
            "Content-Type": "application/json",
          },
        };
        body["password"] = password;
        body["email"] = email;
        body["institution_id"] = institutionId;
        body["type"] = "TEACHER";
        body = JSON.stringify(body);
        axios
          .post(API_URL + "/register_user", body, config)
          .then((res) => {
            setPage("DETAILS");
          })
          .catch((err) => {
            createAlert("ERROR", "Error", err.response.data);
          });
      } else {
        createAlert(
          "ERROR",
          "Password Error.",
          "Password Mismatch or less than 8 characters"
        );
      }
    }
  }

  function checkInstitution() {
    if (institutionId.length == 0) {
      createAlert("ERROR", "Error", "Enter a name for your institution.");
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = institutionId;
      body = JSON.stringify(body);
      axios
        .post(API_URL + "/check_institution", body, config)
        .then((res) => {
          if (res.data["initiated"]) {
            setPage("REGISTER");
          } else {
            createAlert(
              "ERROR",
              "Institution setup Incomplete",
              "This Institution's setup is not yet complete. Try again later."
            );
          }
        })
        .catch((err) => {
          createAlert("ERROR", "Error", err.response.data);
        });
    }
  }

  function submitDetails() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    if (
      firstname.length == 0 ||
      lastname.length == 0 ||
      phoneNumber.length == 0
    ) {
      createAlert("ERROR", "Error", "Please fill all the fields.");
    } else {
      if (phoneNumber.length > 15) {
        createAlert("ERROR", "Error", "Enter valid pincode");
      } else {
        body["email"] = email;
        body["firstname"] = firstname;
        body["middlename"] = middlename;
        body["lastname"] = lastname;
        body["phone_number"] = phoneNumber;

        axios
          .post(API_URL + "/initialize_teacher_profile", body, config)
          .then((res) => {
            setPage("FINISHED");
          })
          .catch((err) => {
            createAlert("ERROR", "Error", err.response.data);
          });
      }
    }
  }

  function getPage() {
    switch (page) {
      case "HELLO":
        return (
          <div style={{ width: "100%" }}>
            <h1 style={{ marginTop: "45px" }}>Welcome to Elemzy!</h1>
            <h3 style={{ marginTop: "-15px" }}>Let's get started.</h3>
            <br></br>
            <p>Enter Institution ID to continue.</p>
            <div style={{ width: "50%" }}>
              <TextField
                fullWidth
                style={{ marginTop: "2px" }}
                value={institutionId}
                size="small"
                onChange={(e) => setInstitutionId(e.target.value)}
                placeholder={"Institution ID"}
                variant="outlined"
              />
            </div>
            <br></br>
            <br></br>
            <Link
              style={{ textDecoration: "none", textAlign: "right" }}
              to="/login"
            >
              Already registered? Login here.
            </Link>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#3182bd",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => checkInstitution()}
            >
              Next
            </Button>
          </div>
        );

      case "REGISTER":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h1 style={{ marginTop: "45px" }}>Registration</h1>
            <h3 style={{ marginTop: "-15px", marginBottom: "25px" }}>
              You are required to create an account to use our services.
            </h3>
            <div style={{ width: "50%" }}>
              <p>Email</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={email}
                fullWidth
                size="small"
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                placeholder={"E-mail"}
                variant="outlined"
              />
              <p>Password</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={password}
                fullWidth
                type={clearText ? "text" : "password"}
                size="small"
                onChange={(e) => setPassword(e.target.value)}
                placeholder={"Password"}
                variant="outlined"
                autoComplete="password"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      size="small"
                      style={{ marginRight: "-8px", paddingLeft: "10px" }}
                      onClick={() => setClearText(!clearText)}
                    >
                      {clearText ? (
                        <VisibilityOffIcon style={{ color: "#3182bd" }} />
                      ) : (
                        <VisibilityIcon style={{ color: "#3182bd" }} />
                      )}
                    </IconButton>
                  ),
                }}
              />
              <p>Confirm Password</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={password2}
                fullWidth
                type={clearText ? "text" : "password"}
                size="small"
                onChange={(e) => setPassword2(e.target.value)}
                placeholder={"Confirm Password"}
                variant="outlined"
              />
            </div>

            <Button
              style={{
                width: "100px",
                backgroundColor: "#3182bd",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => registerUser()}
            >
              Next
            </Button>
          </div>
        );
      case "DETAILS":
        return (
          <div style={{ width: "100%", animation: "fadeInAnimation ease 1s" }}>
            <h3 style={{ marginTop: "45px" }}>
              We would like to know some additional details for your profile
              page.
            </h3>
            <div style={{ display: "flex" }}>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>First Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={firstname}
                  fullWidth
                  size="small"
                  onChange={(e) => setFirstname(e.target.value)}
                  placeholder={"First Name"}
                  variant="outlined"
                  required
                />
              </div>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>Middle Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={middlename}
                  fullWidth
                  size="small"
                  onChange={(e) => setMiddlename(e.target.value)}
                  placeholder={"Middle Name (optional)"}
                  variant="outlined"
                  required
                />
              </div>
              <div style={{ width: "33.33%", marginRight: "25px" }}>
                {" "}
                <p>Last Name</p>
                <TextField
                  style={{ marginTop: "-10px" }}
                  value={lastname}
                  fullWidth
                  size="small"
                  onChange={(e) => setLastname(e.target.value)}
                  placeholder={"Last Name"}
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <div style={{ width: "50%", marginRight: "25px" }}>
              <p>Phone Number</p>
              <TextField
                style={{ marginTop: "-10px" }}
                value={phoneNumber}
                fullWidth
                size="small"
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder={"Phone Number"}
                variant="outlined"
                required
              />
            </div>
            <Button
              style={{
                width: "100px",
                backgroundColor: "#3182bd",
                color: "white",
                fontFamily: "Bahnschrift",
                height: "30px",
                position: "absolute",
                bottom: 30,
                right: 30,
              }}
              onClick={() => submitDetails()}
            >
              Next
            </Button>{" "}
          </div>
        );
      case "FINISHED":
        return (
          <div
            style={{
              width: "100%",
              animation: "fadeInAnimation ease 1s",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h1 style={{ marginTop: "145px", textAlign: "center" }}>
              Finished!
            </h1>
            <CheckCircle
              style={{
                fontSize: "100px",
                color: "#5cb85c",
                textAlign: "center",
              }}
            />
            <br></br>
            Please wait for your account to be approved by
            <p
              style={{
                color: "#3182bd",
                marginTop: "5px",
              }}
            >
              The Principal.
            </p>
          </div>
        );

      default:
        setPage("HELLO");
        return <h1>Shite</h1>;
    }
  }

  function Copyright() {
    return (
      <Typography variant="body2" color="black" align="center">
        {"Copyright © "}
        Elemzy {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <div style={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        open={showSnack}
        onClose={() => setShowSnack(false)}
      >
        <Card style={{ minWidth: "350px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              {snack["type"] == "SUCCESS" ? (
                <CheckCircleIcon
                  style={{ color: "#2ca25f", fontSize: "44px" }}
                />
              ) : snack["type"] == "MESSAGE" ? (
                <ErrorIcon style={{ color: "#3182bd", fontSize: "44px" }} />
              ) : (
                <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
              )}
              <div style={{ marginLeft: "10px" }}>
                <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                <br></br>
                <span>{snack["message"]}</span>
              </div>
            </div>
            <IconButton size="small">
              <CloseIcon
                onClick={() => setShowSnack(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
        </Card>
      </Snackbar>
      <AppBar
        position="fixed"
        elevation={1}
        style={{
          backgroundColor: "white",
          height: "50px",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <a
            style={{ textDecoration: "none" }}
            href={"https://teacher.elemzy.com"}
          >
            <img
              style={{ maxHeight: "40px", marginTop: "-15px" }}
              src={require("../img/appbar.png")}
            />
          </a>
          <a
            style={{ textDecoration: "none" }}
            href={"https://teacher.elemzy.com"}
          >
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                fontSize: "16px",
                marginTop: "3px",
                height: "30px",
                marginTop: "-20px",
              }}
            >
              <ChevronLeftIcon style={{ fontSize: "35px" }} /> Back to Home
            </Button>
          </a>
        </Toolbar>
      </AppBar>
      <div style={{ padding: "50px" }}>{getPage()}</div>
    </div>
  );
}
export default Initialize;
